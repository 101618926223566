export default [
    // Dashboard
    {
      path: '/kadinas/dashboard',
      name: 'kadinas-dashboard',
      component: () => import('@/views/apps/kadinas/dashboard/Dashboard.vue'),
      meta: {
        role: ['kadinas'],
        contentClass: 'kadinas-dashboard',
        navActiveLink: 'kadinas-dashboard',
      },
    },
    {
      path: '/kadinas/peserta/trainingsreq',
      name: 'kadinas-training-requests-peserta.index',
      component: () => import('@/views/apps/kadinas/training-request/Index.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah','kadinas'],
        contentClass: 'training-requests-peserta.index',
        navActiveLink: 'training-requests-peserta.index',
        pageTitle: 'Pelatihan',
        breadcrumb: [
          {
            text: 'Pelatihan',
            active: true, 
          },
        ],
      },
    },
    {
      path: '/kadinas/trainings-request/:trainingId',
      name: 'kadinas-training-requests-peserta.show',
      component: () => import('@/views/apps/kadinas/training-request/classroom-detail/ClassroomDetail.vue'),
      meta: {
        role: ['superadmin', 'admin','staff', 'staffsekolah', 'kadinas'],
        contentClass: 'training-requests-peserta.index',
        navActiveLink: 'training-requests-peserta.index',
        pageTitle: 'Detail Pelatihan',
        back: 'training-requests-peserta.index',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'training-requests-peserta.index',
          },
          {
            text: 'Detail Pelatihan',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/kadinas/trainings-request-kadinas/:trainingId',
      name: 'training-requests-pelatihan.show',
      component: () => import('@/views/apps/kadinas/training-requests/classroom-detail/ClassroomDetail.vue'),
      meta: {
        role: ['superadmin', 'admin','staff', 'staffsekolah', 'kadinas'],
        contentClass: 'training-requests-pelatihan.index',
        navActiveLink: 'training-requests-pelatihan.index',
        pageTitle: 'Detail Pelatihan',
        back: 'training-requests-pelatihan.index',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'training-requests-pelatihan.index',
          },
          {
            text: 'Detail Pelatihan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pelatihan/trainingsreq',
      name: 'training-requests-pelatihan.index',
      component: () => import('@/views/apps/kadinas/training-requests/Index.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah','kadinas'],
        contentClass: 'training-requests-pelatihan.index',
        navActiveLink: 'training-requests-pelatihan.index',
        pageTitle: 'Pelatihan',
        breadcrumb: [
          {
            text: 'Pelatihan',
            active: true, 
          },
        ],
      },
    },
    
    {
      path: '/kadinas/trainings-request-pelatihan/:trainingId/chapters/:chapterId',
      name: 'training-requests-pelatihan-chapters.show',
      component: () => import('@/views/apps/kadinas/training-requests/classroom-material/ClassroomMaterial.vue'),
      meta: {
        role: ['superadmin', 'admin','staff', 'staffsekolah','kadinas'],
        contentClass: 'training-requests-pelatihan.index',
        navActiveLink: 'training-requests-pelatihan.index',
        pageTitle: 'Kelas',
        back: 'classroom-detail',
        breadcrumb: [
          {
            text: 'Kelas',
            to: 'classroom',
          },
          {
            text: 'Detail',
            to: 'classroom-detail',
          },
          {
            text: 'Materi',
            active: true,
          },
        ],
      },
    },
  ]
  