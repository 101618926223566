export default [
  {
    path: '/',
    name: 'guest-trainings.index',
    component: () => import('@/views/apps/guest/Index.vue'),
    meta: {
      role: ['guest'],
      guestMode: true,
      resource: 'guest-trainings.index',
      contentClass: 'guest-trainings.index',
    },
  },
]
