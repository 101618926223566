export default {
  namespaced: true,
  state: {
    searchQuery: '',
  },
  getters: {
    searchQuery: state => state.searchQuery,
  },
  mutations: {
    SET_SEARCH_QUERY(state, val) {
      state.searchQuery = val
    },
  },
  actions: {},
}
