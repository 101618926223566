import axios from "@/libs/axios";

const ssoBaseURL = `${process.env.VUE_APP_SSO_BASE_URL}`;
const appBaseURL = `${process.env.VUE_APP_BASE_URL}`;

export function reqLogin(payload) {
  return axios.post("api/auth/login", payload);
}

/**
 * Exchange SSO token to API token
 * @param {string} token 
 * @returns Promise<any>
 */
export async function exchangeSSOToken(token) {
  const form = new FormData();
  form.append("token", token);
  const { data, status } = await axios.post("api/auth/exchange-token", form);

  if (status !== 200) {
    throw new Error("Failed to exchange token");
  }

  return data.data;
}

export function reqRegister(payload) {
  return axios.post("api/auth/register", payload);
}
export function reqGetMe(payload) {
  return axios.get("api/auth/me", { params: payload });
}
export function reqUpdateProfile(payload) {
  return axios.post("api/auth/change-profile", payload);
}
export function reqUpdatePassword(payload) {
  return axios.post("api/auth/change-password", payload);
}

export function reqRegisterSekolah(payload) {
  return axios.post("api/auth/register-sekolah", payload);
}
export function reqVerifyOtp(payload) {
  return axios.post("api/auth/verify-otp", payload);
}
export function convertSsoToken(code) {
  return axios.post(`${ssoBaseURL}/oauth/token`, {
    grant_type: "authorization_code",
    client_id: process.env.VUE_APP_SSO_CREDENTIAL_ID,
    client_secret: process.env.VUE_APP_SSO_CREDENTIAL_SECRET,
    redirect_uri: `${appBaseURL}/auth/callback`,
    code,
  });
}
export function reqGetMeFromSsoServer(token) {
  return axios.get(`${ssoBaseURL}/api/auth/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
