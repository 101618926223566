export default [
  // Super Administrator
  // Dashboard
  {
    path: '/superadmin/dashboard/training',
    name: 'superadmin-dashboard-training',
    component: () => import('@/views/apps/superadmin/dashboard/DashboardTraining.vue'),
    meta: {
      navActiveLink: 'superadmin-dashboard-training',
    },
  },
  {
    path: '/superadmin/dashboard/teacher',
    name: 'superadmin-dashboard-teacher',
    component: () => import('@/views/apps/superadmin/dashboard/DashboardTeacher.vue'),
    meta: {
      navActiveLink: 'superadmin-dashboard-teacher',
    },
  },
  {
    path: '/back-up',
    name: 'back-up.index',
    component: () => import('@/views/apps/superadmin/backup/Index.vue'),
    meta: {
      role: ['superadmin'],
    },
  },
  {
    path: '/back-up/create',
    name: 'back-up.create',
    component: () => import('@/views/apps/superadmin/backup/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'back-up.index',
      navActiveLink: 'back-up.index',
    },
  },
  {
    path: '/staffs',
    name: 'staffs.index',
    component: () => import('@/views/apps/superadmin/staff/Index.vue'),
    meta: {
      role: ['superadmin', 'staffsekolah'],
    },
  },
  {
    path: '/staffs/create',
    name: 'staffs.create',
    component: () => import('@/views/apps/superadmin/staff/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'staffs.index',
      navActiveLink: 'staffs.index',
    },
  },
  {
    path: '/staffs/:id/edit',
    name: 'staffs.edit',
    component: () => import('@/views/apps/superadmin/staff/Edit.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'staffs.index',
      navActiveLink: 'staffs.index',
    },
  },
  {
    path: '/superadmin/letter-of-assignments',
    name: 'superadmin-letter-of-assignments.index',
    component: () => import('@/views/apps/superadmin/letter-of-assignment/Index.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'superadmin-letter-of-assignments.index',
      navActiveLink: 'superadmin-letter-of-assignments.index',
    },
  },
  {
    path: '/superadmin/letter-of-assignments/create',
    name: 'superadmin-letter-of-assignments.create',
    component: () => import('@/views/apps/superadmin/letter-of-assignment/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'superadmin-letter-of-assignments.index',
      navActiveLink: 'superadmin-letter-of-assignments.index',
    },
  },
  {
    path: '/tu/letter-of-assignments/:id/show',
    name: 'superadmin-letter-of-assignments.show',
    component: () => import('@/views/apps/superadmin/letter-of-assignment/Show.vue'),
    meta: {
      role: ['superadmin', 'tu'],
      contentClass: 'superadmin-letter-of-assignments.index',
      navActiveLink: 'superadmin-letter-of-assignments.index',
    },
  },
  {
    path: '/superadmin/letter-of-assignments/:id/edit',
    name: 'superadmin-letter-of-assignments.edit',
    component: () => import('@/views/apps/superadmin/letter-of-assignment/Edit.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'superadmin-letter-of-assignments.index',
      navActiveLink: 'superadmin-letter-of-assignments.index',
    },
  },
  {
    path: '/superadmin/trainings',
    name: 'superadmin-trainings.index',
    component: () => import('@/views/apps/trainer/training/Index.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Pelatihan',
      breadcrumb: [
        {
          text: 'Pelatihan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/create',
    name: 'superadmin-trainings.create',
    component: () => import('@/views/apps/trainer/training/Create.vue'),
    meta: {
      role: ['superadmin', 'staffsekolah'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Tambah Pelatihan',
      breadcrumb: [
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/:trainingId',
    name: 'superadmin-trainings.show',
    component: () => import('@/views/apps/trainer/training/classroom-detail/ClassroomDetail.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Detail Pelatihan',
      back: 'trainings.index',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          active: true,
        },
      ],
    },
  },
  // chapters
  {
    path: '/superadmin/trainings/:trainingId/chapters/:chapterId',
    name: 'superadmin-chapters.show',
    component: () => import('@/views/apps/trainer/training/classroom-material/ClassroomMaterial.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/:trainingId/chapters/create',
    name: 'superadmin-chapters.create',
    component: () => import('@/views/apps/trainer/training/classroom-material/classroom-material-add/ClassroomMaterialAdd.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Kelas',
      back: 'classroom-detail-material',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          to: 'classroom-detail-material',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },

  // Classroom Task
  {
    path: '/superadmin/trainings/:trainingId/chapters/:chapterId/tasks',
    name: 'superadmin-chapter-tasks.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/DetailTask.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Tugas',
      back: 'trainings.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/multiplechoice-questions',
    name: 'superadmin-task-multiplechoice-questions.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/task-question/MultipleChoice.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Tugas',
      back: 'chapter-tasks.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          to: 'chapter-tasks.show',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/:trainingId/task-results/:taskResultId/correction',
    name: 'superadmin-task-results.correction',
    component: () => import('@/views/apps/trainer/training/classroom-task/task-correction/Essay.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Koreksi Tugas',
      back: 'trainings.show',
      tab: '2',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Tugas',
          to: 'trainings.show',
        },
        {
          text: 'Koreksi Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/essay-questions',
    name: 'superadmin-task-essay-questions.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/task-question/Essay.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Tugas',
      back: 'chapter-tasks.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          to: 'chapter-tasks.show',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/superadmin/trainings/:trainingId/chapters/:chapterId/tasks/create',
    name: 'superadmin-chapter-tasks.create',
    component: () => import('@/views/apps/trainer/training/classroom-task/classroom-task-add/ClassroomTaskAdd.vue'),
    meta: {
      role: ['superadmin', 'admin', 'superadmin'],
      contentClass: 'superadmin-trainings.index',
      navActiveLink: 'superadmin-trainings.index',
      pageTitle: 'Tambah Tugas',
      back: 'trainings.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tambah Tugas',
          active: true,
        },
      ],
    },
  },

  {
    path: '/superadmin/training-requests',
    name: 'superadmin-training-requests.index',
    component: () => import('@/views/apps/staff/training-request/Index.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'superadmin-training-requests.index',
      pageTitle: 'Request Pelatihan',
      breadcrumb: [
        {
          text: 'Request Pelatihan',
          active: true,
        },
      ],
    },
  },

  // staff sekolah
  {
    path: '/staff-sekolah',
    name: 'staff-sekolah.index',
    component: () => import('@/views/apps/superadmin/staff-sekolah/Index.vue'),
    meta: {
      role: ['superadmin'],
    },
  },
  {
    path: '/staff-sekolah/create',
    name: 'staff-sekolah.create',
    component: () => import('@/views/apps/superadmin/staff-sekolah/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'staff-sekolah.index',
      navActiveLink: 'staff-sekolah.index',
    },
  },
  {
    path: '/staff-sekolah/:id/edit',
    name: 'staff-sekolah.edit',
    component: () => import('@/views/apps/superadmin/staff-sekolah/Edit.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'staff-sekolah.index',
      navActiveLink: 'staff-sekolah.index',
    },
  },

  {
    path: '/bpsdms',
    name: 'bpsdms.index',
    component: () => import('@/views/apps/superadmin/bpsdm/Index.vue'),
    meta: {
      role: ['superadmin'],
    },
  },
  {
    path: '/bpsdms/create',
    name: 'bpsdms.create',
    component: () => import('@/views/apps/superadmin/bpsdm/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'bpsdms.index',
      navActiveLink: 'bpsdms.index',
    },
  },
  {
    path: '/bpsdms/:id/edit',
    name: 'bpsdms.edit',
    component: () => import('@/views/apps/superadmin/bpsdm/Edit.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'bpsdms.index',
      navActiveLink: 'bpsdms.index',
    },
  },

  {
    path: '/ketenagaan',
    name: 'ketenagaan.index',
    component: () => import('@/views/apps/superadmin/ketenagaan/Index.vue'),
    meta: {
      role: ['superadmin'],
    },
  },
  {
    path: '/ketenagaan/create',
    name: 'ketenagaan.create',
    component: () => import('@/views/apps/superadmin/ketenagaan/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'ketenagaan.index',
      navActiveLink: 'ketenagaan.index',
    },
  },
  {
    path: '/ketenagaan/:id/edit',
    name: 'ketenagaan.edit',
    component: () => import('@/views/apps/superadmin/ketenagaan/Edit.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'ketenagaan.index',
      navActiveLink: 'ketenagaan.index',
    },
  },

  {
    path: '/kadinas',
    name: 'kadinas.index',
    component: () => import('@/views/apps/superadmin/kadinas/Index.vue'),
    meta: {
      role: ['superadmin'],
    },
  },
  {
    path: '/kadinas/create',
    name: 'kadinas.create',
    component: () => import('@/views/apps/superadmin/kadinas/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'kadinas.index',
      navActiveLink: 'kadinas.index',
    },
  },
  {
    path: '/kadinas/:id/edit',
    name: 'kadinas.edit',
    component: () => import('@/views/apps/superadmin/kadinas/Edit.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'kadinas.index',
      navActiveLink: 'kadinas.index',
    },
  },

  {
    path: '/npsns',
    name: 'npsns.index',
    component: () => import('@/views/apps/superadmin/npsn/Index.vue'),
    meta: {
      role: ['superadmin'],
    },
  },
  {
    path: '/npsns/create',
    name: 'npsns.create',
    component: () => import('@/views/apps/superadmin/npsn/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'npsns.index',
      navActiveLink: 'npsns.index',
    },
  },
  {
    path: '/npsns/:id/edit',
    name: 'npsns.edit',
    component: () => import('@/views/apps/superadmin/npsn/Edit.vue'),
    meta: {
      role: ['superadmin', 'admin'],
      contentClass: 'npsns.index',
      navActiveLink: 'npsns.index',
    },
  },

  {
    path: '/ttds',
    name: 'ttds.index',
    component: () => import('@/views/apps/superadmin/masterttd/Index.vue'),
    meta: {
      role: ['superadmin', 'admin'],
    },
  },
  {
    path: '/ttds/:id/edit',
    name: 'ttds.edit',
    component: () => import('@/views/apps/superadmin/masterttd/Edit.vue'),
    meta: {
      role: ['superadmin', 'admin'],
      contentClass: 'ttds.index',
      navActiveLink: 'ttds.index',
    },
  },
  {
    path: '/ttds/create',
    name: 'ttds.create',
    component: () => import('@/views/apps/superadmin/masterttd/Create.vue'),
    meta: {
      role: ['superadmin', 'admin'],
      contentClass: 'ttds.index',
      navActiveLink: 'ttds.index',
    },
  },

  // Certificate
  {
    path: '/certificate',
    name: 'certificate.index',
    component: () => import('@/views/apps/superadmin/certificate/Index.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'certificate.index',
      navActiveLink: 'certificate.index',
    },
  },
  {
    path: '/certificate/create',
    name: 'certificate.create',
    component: () => import('@/views/apps/superadmin/certificate/Create.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'certificate.create',
      navActiveLink: 'certificate.create',
    },
  },
  {
    path: '/certificate/edit/:id',
    name: 'certificate.edit',
    component: () => import('@/views/apps/superadmin/certificate/Edit.vue'),
    meta: {
      role: ['superadmin'],
      contentClass: 'certificate.edit',
      navActiveLink: 'certificate.edit',
    },
  },
]
