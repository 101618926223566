export default [
  // School Admin
  // Dashboard
  {
    path: '/staff/dashboard',
    name: 'staff-dashboard',
    component: () => import('@/views/apps/staff/dashboard/Dashboard.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      navActiveLink: 'staff-dashboard',
    },
  },

  // LOA
  {
    path: '/letter-of-assignments',
    name: 'staff-letter-of-assignments.index',
    component: () => import('@/views/apps/staff/letter-of-assignment/Index.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      navActiveLink: 'staff-letter-of-assignments.index',
    },
  },
  {
    path: '/letter-of-assignments/:id',
    name: 'staff-letter-of-assignments.show',
    component: () => import('@/views/apps/staff/letter-of-assignment/Show.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      contentClass: 'staff-letter-of-assignments.index',
      navActiveLink: 'staff-letter-of-assignments.index',
      back: 'letter-of-assignments.index',
      breadcrumb: [
        {
          text: 'Surat Penugasan',
          to: 'letter-of-assignments.index',
        },
        {
          text: 'Detail Surat Penugasan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainers',
    name: 'trainers.index',
    component: () => import('@/views/apps/staff/trainer/Index.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu', 'staffsekolah'],
    },
  },
  {
    path: '/trainers/create',
    name: 'trainers.create',
    component: () => import('@/views/apps/staff/trainer/Create.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      contentClass: 'trainers.index',
      navActiveLink: 'trainers.index',
    },
  },
  {
    path: '/trainers/:id/edit',
    name: 'trainers.edit',
    component: () => import('@/views/apps/staff/trainer/Edit.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
    },
  },

  {
    path: '/authorities',
    name: 'authorities.index',
    component: () => import('@/views/apps/staff/authority/Index.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu', 'staffsekolah'],
    },
  },
  {
    path: '/authorities/create',
    name: 'authorities.create',
    component: () => import('@/views/apps/staff/authority/Create.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      contentClass: 'authorities.index',
      navActiveLink: 'authorities.index',
    },
  },
  {
    path: '/authorities/:id/edit',
    name: 'authorities.edit',
    component: () => import('@/views/apps/staff/authority/Edit.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
    },
  },
  {
    path: '/regencies',
    name: 'regencies.index',
    component: () => import('@/views/apps/staff/regency/Index.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu', 'staffsekolah'],
    },
  },
  {
    path: '/regencies/create',
    name: 'regencies.create',
    component: () => import('@/views/apps/staff/regency/Create.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      contentClass: 'regencies.index',
      navActiveLink: 'regencies.index',
    },
  },
  {
    path: '/regencies/:id/edit',
    name: 'regencies.edit',
    component: () => import('@/views/apps/staff/regency/Edit.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
    },
  },
  {
    path: '/instances',
    name: 'instances.index',
    component: () => import('@/views/apps/staff/instance/Index.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu', 'staffsekolah'],
    },
  },
  {
    path: '/instances/create',
    name: 'instances.create',
    component: () => import('@/views/apps/staff/instance/Create.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      contentClass: 'instances.index',
      navActiveLink: 'instances.index',
    },
  },
  {
    path: '/instances/:id/edit',
    name: 'instances.edit',
    component: () => import('@/views/apps/staff/instance/Edit.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
    },
  },
  {
    path: '/guides',
    name: 'guides.index',
    component: () => import('@/views/apps/staff/guide/Index.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
    },
  },
  {
    path: '/guides/create',
    name: 'guides.create',
    component: () => import('@/views/apps/staff/guide/Create.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      contentClass: 'guides.index',
      navActiveLink: 'guides.index',
    },
  },
  {
    path: '/guides/:id/edit',
    name: 'guides.edit',
    component: () => import('@/views/apps/staff/guide/Edit.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
    },
  },
  {
    path: '/help-contacts',
    name: 'help-contacts.index',
    component: () => import('@/views/apps/staff/help-contact/Index.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
    },
  },
  {
    path: '/help-contacts/create',
    name: 'help-contacts.create',
    component: () => import('@/views/apps/staff/help-contact/Create.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      contentClass: 'help-contacts.index',
      navActiveLink: 'help-contacts.index',
    },
  },
  {
    path: '/help-contacts/:id/edit',
    name: 'help-contacts.edit',
    component: () => import('@/views/apps/staff/help-contact/Edit.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
    },
  },
  {
    path: '/users',
    name: 'users.index',
    component: () => import('@/views/apps/staff/user/Index.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu', 'bpsdm', 'kadinas'],
    },
  },
  {
    path: '/users/create',
    name: 'users.create',
    component: () => import('@/views/apps/staff/user/Create.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu', 'bpsdm', 'kadinas'],
      contentClass: 'users.index',
      navActiveLink: 'users.index',
    },
  },
  {
    path: '/users/:id',
    name: 'users.show',
    component: () => import('@/views/apps/staff/user/Show.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu', 'bpsdm', 'kadinas'],
    },
  },
  {
    path: '/users/:id/edit',
    name: 'users.edit',
    component: () => import('@/views/apps/staff/user/Edit.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu', 'bpsdm', 'kadinas'],
    },
  },
  {
    path: '/users/:id/ceritificates/:certificateId',
    name: 'users.certificates.show',
    component: () => import('@/views/apps/staff/user/certificate/Show.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu', 'bpsdm', 'kadinas'],
    },
  },

  {
    path: '/staff/trainings',
    name: 'staff-trainings.index',
    component: () => import('@/views/apps/trainer/training/Index.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu', 'bpsdm', 'kadinas'],
      contentClass: 'staff-trainings.index',
      navActiveLink: 'staff-trainings.index',
      pageTitle: 'Pelatihan',
      breadcrumb: [
        {
          text: 'Pelatihan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staff/trainings/create',
    name: 'staff-trainings.create',
    component: () => import('@/views/apps/trainer/training/Create.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      contentClass: 'staff-trainings.index',
      navActiveLink: 'staff-trainings.index',
      pageTitle: 'Tambah Pelatihan',
      breadcrumb: [
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staff/trainings/:trainingId',
    name: 'staff-trainings.show',
    component: () => import(
      '@/views/apps/trainer/training/classroom-detail/ClassroomDetail.vue'
    ),
    meta: {
      role: ['superadmin', 'admin', 'tu', 'staff'],
      contentClass: 'staff-trainings.index',
      navActiveLink: 'staff-trainings.index',
      pageTitle: 'Detail Pelatihan',
      back: 'trainings.index',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          active: true,
        },
      ],
    },
  },
  // chapters
  {
    path: '/staff/trainings/:trainingId/chapters/:chapterId',
    name: 'staff-chapters.show',
    component: () => import(
      '@/views/apps/trainer/training/classroom-material/ClassroomMaterial.vue'
    ),
    meta: {
      role: ['superadmin', 'admin', 'tu', 'staff'],
      contentClass: 'staff-trainings.index',
      navActiveLink: 'staff-trainings.index',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staff/trainings/:trainingId/chapters/create',
    name: 'staff-chapters.create',
    component: () => import(
      '@/views/apps/trainer/training/classroom-material/classroom-material-add/ClassroomMaterialAdd.vue'
    ),
    meta: {
      role: ['superadmin', 'admin', 'tu', 'staff'],
      contentClass: 'staff-trainings.index',
      navActiveLink: 'staff-trainings.index',
      pageTitle: 'Kelas',
      back: 'classroom-detail-material',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          to: 'classroom-detail-material',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },

  // Classroom Task
  {
    path: '/staff/trainings/:trainingId/chapters/:chapterId/tasks',
    name: 'staff-chapter-tasks.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/DetailTask.vue'),
    meta: {
      role: ['superadmin', 'admin', 'tu', 'staff'],
      contentClass: 'staff-trainings.index',
      navActiveLink: 'staff-trainings.index',
      pageTitle: 'Tugas',
      back: 'trainings.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staff/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/multiplechoice-questions',
    name: 'staff-task-multiplechoice-questions.show',
    component: () => import(
      '@/views/apps/trainer/training/classroom-task/task-question/MultipleChoice.vue'
    ),
    meta: {
      role: ['superadmin', 'admin', 'tu', 'staff'],
      contentClass: 'staff-trainings.index',
      navActiveLink: 'staff-trainings.index',
      pageTitle: 'Tugas',
      back: 'chapter-tasks.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          to: 'chapter-tasks.show',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staff/trainings/:trainingId/task-results/:taskResultId/correction',
    name: 'staff-task-results.correction',
    component: () => import(
      '@/views/apps/trainer/training/classroom-task/task-correction/Essay.vue'
    ),
    meta: {
      role: ['superadmin', 'admin', 'tu', 'staff'],
      contentClass: 'staff-trainings.index',
      navActiveLink: 'staff-trainings.index',
      pageTitle: 'Koreksi Tugas',
      back: 'trainings.show',
      tab: '2',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Tugas',
          to: 'trainings.show',
        },
        {
          text: 'Koreksi Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staff/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/essay-questions',
    name: 'staff-task-essay-questions.show',
    component: () => import(
      '@/views/apps/trainer/training/classroom-task/task-question/Essay.vue'
    ),
    meta: {
      role: ['superadmin', 'admin', 'tu', 'staff'],
      contentClass: 'staff-trainings.index',
      navActiveLink: 'staff-trainings.index',
      pageTitle: 'Tugas',
      back: 'chapter-tasks.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          to: 'chapter-tasks.show',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staff/trainings/:trainingId/chapters/:chapterId/tasks/create',
    name: 'staff-chapter-tasks.create',
    component: () => import(
      '@/views/apps/trainer/training/classroom-task/classroom-task-add/ClassroomTaskAdd.vue'
    ),
    meta: {
      role: ['superadmin', 'admin', 'tu', 'staff'],
      contentClass: 'staff-trainings.index',
      navActiveLink: 'staff-trainings.index',
      pageTitle: 'Tambah Tugas',
      back: 'trainings.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tambah Tugas',
          active: true,
        },
      ],
    },
  },

  {
    path: '/staff/training-requests',
    name: 'staff-training-requests.index',
    component: () => import('@/views/apps/staff/training-request/Index.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      contentClass: 'staff-training-requests.index',
      pageTitle: 'Request Pelatihan',
      breadcrumb: [
        {
          text: 'Request Pelatihan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staff/training-requests/:trainingRequestId',
    name: 'staff-training-requests.show',
    component: () => import('@/views/apps/staff/training-request/Show.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      contentClass: 'staff-training-requests.index',
      navActiveLink: 'staff-training-requests.index',
      pageTitle: 'Detail Request Pelatihan',
      back: 'training-requests.index',
      breadcrumb: [
        {
          text: 'Request Pelatihan',
          to: 'training-requests.index',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staff/training-requests/:trainingRequestId/edit',
    name: 'staff-training-requests.edit',
    component: () => import('@/views/apps/staff/training-request/Edit.vue'),
    meta: {
      role: ['superadmin', 'staff', 'tu'],
      contentClass: 'staff-training-requests.index',
      navActiveLink: 'staff-training-requests.index',
      pageTitle: 'Edit Request Pelatihan',
      back: 'training-requests.index',
      breadcrumb: [
        {
          text: 'Request Pelatihan',
          to: 'training-requests.index',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  {
    path: '/staffsekolah/trainings/:trainingId',
    name: 'staff-trainings.show.sekolah',
    component: () => import(
      '@/views/apps/sekolah/training/classroom-detail/ClassroomDetail.vue'
    ),
    meta: {
      role: ['superadmin', 'admin', 'tu', 'staff', 'staffsekolah'],
      contentClass: 'trainings-sekolah.index',
      navActiveLink: 'trainings-sekolah.index',
      pageTitle: 'Detail Pelatihan',
      back: 'trainings-sekolah.index',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings-sekolah.index',
        },
        {
          text: 'Detail Pelatihan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staffsekolah/trainings',
    name: 'staff-trainings-sekolah.index',
    component: () => import('@/views/apps/sekolah/training/Index.vue'),
    meta: {
      role: ['superadmin', 'admin', 'tu', 'staff', 'staffsekolah'],
      contentClass: 'trainings-sekolah.index',
      navActiveLink: 'trainings-sekolah.index',
      pageTitle: 'Pelatihan',
      breadcrumb: [
        {
          text: 'Pelatihan',
          active: true,
        },
      ],
    },
  },
]
