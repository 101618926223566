import axiosLib from "axios";
import router from "@/router";

const axios = axiosLib.create({
  baseURL: `${process.env.VUE_APP_BASE_API_URL}`,
});

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // config.headers["Content-Type"] = "application/json";
    config.headers["Content-Type"] = "multipart/form-data";
    return config;
  },
  (error) => Promise.reject(error)
);
axios.interceptors.response.use(
  (response) => {
    if (response.status !== 200 && response.status !== 201) {
      return Promise.reject(response);
    }
    return response;
  },
  (error) => {
    if (error.response.status == "500") {
      alert("Something went wrong on server");
    } else if (error.response.status == "401") {
      localStorage.removeItem("accessToken");
      router.push({ name: "auth-login" });
    } else if (error.code == "ERR_NETWORK") {
      alert("Something went wrong with your network");
    }
    console.log("axios error :");
    console.log(error.code);
    return Promise.reject(error);
  }
);

export default axios;
