export default [
    // user
    // Dashboard
    {
      path: '/staffsekolah/dashboard',
      name: 'staffsekolah-dashboard',
      component: () => import('@/views/apps/sekolah/dashboard/Dashboard.vue'),
      meta: {
        role: ['staffsekolah'],
        contentClass: 'staffsekolah-dashboard',
        navActiveLink: 'staffsekolah-dashboard',
      },
    },
    {
      path: '/staffsekolah/trainings',
      name: 'trainings-sekolah.index',
      component: () => import('@/views/apps/sekolah/training/Index.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah','bpsdm', 'kadinas'],
        contentClass: 'trainings-sekolah.index',
        navActiveLink: 'trainings-sekolah.index',
        pageTitle: 'Pelatihan',
        breadcrumb: [
          {
            text: 'Pelatihan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings/create',
      name: 'staffsekolah-trainings.create',
      component: () => import('@/views/apps/sekolah/training-request/Create.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah','bpsdm', 'kadinas'],
        contentClass: 'training-requests-sekolah.index',
        navActiveLink: 'training-requests-sekolah.index',
        pageTitle: 'Tambah Pelatihan',
        breadcrumb: [
          {
            text: 'Tambah',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings/:trainingId',
      name: 'staffsekolah-trainings.show',
      component: () => import('@/views/apps/sekolah/training/classroom-detail/ClassroomDetail.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah'],
        contentClass: 'trainings-sekolah.index',
        navActiveLink: 'trainings-sekolah.index',
        pageTitle: 'Detail Pelatihan',
        back: 'staff-trainings.index',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'staff-trainings.index',
          },
          {
            text: 'Detail Pelatihan',
            active: true,
          },
        ],
      },
    },
    // chapters
    {
      path: '/staffsekolah/trainings/:trainingId/chapters/:chapterId',
      name: 'staffsekolah-chapters.show',
      component: () => import('@/views/apps/sekolah/training-request/classroom-material/ClassroomMaterial.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah'],
        contentClass: 'trainings-sekolah.index',
        navActiveLink: 'trainings-sekolah.index',
        pageTitle: 'Kelas',
        back: 'classroom-detail',
        breadcrumb: [
          {
            text: 'Kelas',
            to: 'classroom',
          },
          {
            text: 'Detail',
            to: 'classroom-detail',
          },
          {
            text: 'Materi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings/:trainingId/chapters/create',
      name: 'staffsekolah-chapters.create',
      component: () => import('@/views/apps/sekolah/training-request/classroom-material/classroom-material-add/ClassroomMaterialAdd.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah'],
        contentClass: 'staff-trainings.index',
        navActiveLink: 'staff-trainings.index',
        pageTitle: 'Kelas',
        back: 'classroom-detail-material',
        breadcrumb: [
          {
            text: 'Kelas',
            to: 'classroom',
          },
          {
            text: 'Detail',
            to: 'classroom-detail',
          },
          {
            text: 'Materi',
            to: 'classroom-detail-material',
          },
          {
            text: 'Tambah',
            active: true,
          },
        ],
      },
    },
  
    // Classroom Task
    {
      path: '/staffsekolah/trainings/:trainingId/chapters/:chapterId/tasks',
      name: 'staffsekolah-chapter-tasks.show',
      component: () => import('@/views/apps/sekolah/training-request/classroom-task/DetailTask.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah'],
        contentClass: 'staff-trainings.index',
        navActiveLink: 'staff-trainings.index',
        pageTitle: 'Tugas',
        back: 'trainings.show',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'trainings.index',
          },
          {
            text: 'Detail Pelatihan',
            to: 'trainings.show',
          },
          {
            text: 'Tugas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/multiplechoice-questions',
      name: 'staffsekolah-task-multiplechoice-questions.show',
      component: () => import('@/views/apps/sekolah/training-request/classroom-task/task-question/MultipleChoice.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah'],
        contentClass: 'staff-trainings.index',
        navActiveLink: 'staff-trainings.index',
        pageTitle: 'Tugas',
        back: 'chapter-tasks.show',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'trainings.index',
          },
          {
            text: 'Detail Pelatihan',
            to: 'trainings.show',
          },
          {
            text: 'Tugas',
            to: 'chapter-tasks.show',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings/:trainingId/task-results/:taskResultId/correction',
      name: 'staffsekolah-task-results.correction',
      component: () => import('@/views/apps/sekolah/training-request/classroom-task/task-correction/Essay.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah'],
        contentClass: 'staff-trainings.index',
        navActiveLink: 'staff-trainings.index',
        pageTitle: 'Koreksi Tugas',
        back: 'trainings.show',
        tab: '2',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'trainings.index',
          },
          {
            text: 'Tugas',
            to: 'trainings.show',
          },
          {
            text: 'Koreksi Tugas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/essay-questions',
      name: 'staffsekolah-task-essay-questions.show',
      component: () => import('@/views/apps/sekolah/training-request/classroom-task/task-question/Essay.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah'],
        contentClass: 'staff-trainings.index',
        navActiveLink: 'staff-trainings.index',
        pageTitle: 'Tugas',
        back: 'chapter-tasks.show',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'trainings.index',
          },
          {
            text: 'Detail Pelatihan',
            to: 'trainings.show',
          },
          {
            text: 'Tugas',
            to: 'chapter-tasks.show',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings/:trainingId/chapters/:chapterId/tasks/create',
      name: 'staffsekolah-chapter-tasks.create',
      component: () => import('@/views/apps/sekolah/training-request/classroom-task/classroom-task-add/ClassroomTaskAdd.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah'],
        contentClass: 'staff-trainings.index',
        navActiveLink: 'staff-trainings.index',
        pageTitle: 'Tambah Tugas',
        back: 'trainings.show',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'trainings.index',
          },
          {
            text: 'Detail Pelatihan',
            to: 'trainings.show',
          },
          {
            text: 'Tambah Tugas',
            active: true,
          },
        ],
      },
    },
  
  
    

    {
      path: '/staffsekolah/trainingsreq',
      name: 'training-requests-sekolah.index',
      component: () => import('@/views/apps/sekolah/training-request/Index.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah','bpsdm', 'kadinas'],
        contentClass: 'training-requests-sekolah.index',
        navActiveLink: 'training-requests-sekolah.index',
        pageTitle: 'Pelatihan',
        breadcrumb: [
          {
            text: 'Pelatihan',
            active: true, 
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings-request/create',
      name: 'training-requests-sekolah.create',
      component: () => import('@/views/apps/sekolah/training-request/Create.vue'),
      meta: {
        role: ['superadmin', 'admin', 'tu', 'staff','staffsekolah'],
        contentClass: 'training-requests-sekolah.index',
        navActiveLink: 'training-requests-sekolah.index',
        pageTitle: 'Tambah Pelatihan',
        breadcrumb: [
          {
            text: 'Tambah',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings-request/:trainingId',
      name: 'training-requests-sekolah.show',
      component: () => import('@/views/apps/sekolah/training-request/classroom-detail/ClassroomDetail.vue'),
      meta: {
        role: ['superadmin', 'admin','staff', 'staffsekolah','bpsdm', 'kadinas'],
        contentClass: 'training-requests-sekolah.index',
        navActiveLink: 'training-requests-sekolah.index',
        pageTitle: 'Detail Pelatihan',
        back: 'training-requests-sekolah.index',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'training-requests-sekolah.index',
          },
          {
            text: 'Detail Pelatihan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings-request/:trainingId/chapters/:chapterId',
      name: 'training-requests-sekolah-chapters.show',
      component: () => import('@/views/apps/sekolah/training-request/classroom-material/ClassroomMaterial.vue'),
      meta: {
        role: ['superadmin', 'admin','staff', 'staffsekolah','bpsdm', 'kadinas'],
        contentClass: 'training-requests-sekolah.index',
        navActiveLink: 'training-requests-sekolah.index',
        pageTitle: 'Kelas',
        back: 'classroom-detail',
        breadcrumb: [
          {
            text: 'Kelas',
            to: 'classroom',
          },
          {
            text: 'Detail',
            to: 'classroom-detail',
          },
          {
            text: 'Materi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings-request/:trainingId/chapters/create',
      name: 'superadmin-chapters.create', 
      component: () => import('@/views/apps/sekolah/training-request/classroom-material/classroom-material-add/ClassroomMaterialAdd.vue'),
      meta: {
        role: ['superadmin', 'admin','staff', 'staffsekolah'],
        contentClass: 'superadmin-trainings.index',
        navActiveLink: 'superadmin-trainings.index',
        pageTitle: 'Kelas',
        back: 'classroom-detail-material',
        breadcrumb: [
          {
            text: 'Kelas',
            to: 'classroom',
          },
          {
            text: 'Detail',
            to: 'classroom-detail',
          },
          {
            text: 'Materi',
            to: 'classroom-detail-material',
          },
          {
            text: 'Tambah',
            active: true,
          },
        ],
      },
    },
  
    // Classroom Task
    {
      path: '/staffsekolah/trainings-request/:trainingId/chapters/:chapterId/tasks',
      name: 'superadmin-chapter-tasks.show',
      component: () => import('@/views/apps/sekolah/training-request/classroom-task/DetailTask.vue'),
      meta: {
        role: ['superadmin', 'admin','staff', 'staffsekolah','bpsdm', 'kadinas'],
        contentClass: 'superadmin-trainings.index',
        navActiveLink: 'superadmin-trainings.index',
        pageTitle: 'Tugas',
        back: 'trainings.show',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'trainings.index',
          },
          {
            text: 'Detail Pelatihan',
            to: 'trainings.show',
          },
          {
            text: 'Tugas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings-request/:trainingId/chapters/:chapterId/tasks/:taskId/multiplechoice-questions',
      name: 'superadmin-task-multiplechoice-questions.show',
      component: () => import('@/views/apps/sekolah/training-request/classroom-task/task-question/MultipleChoice.vue'),
      meta: {
        role: ['superadmin', 'admin','staff', 'staffsekolah'],
        contentClass: 'superadmin-trainings.index',
        navActiveLink: 'superadmin-trainings.index',
        pageTitle: 'Tugas',
        back: 'chapter-tasks.show',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'trainings.index',
          },
          {
            text: 'Detail Pelatihan',
            to: 'trainings.show',
          },
          {
            text: 'Tugas',
            to: 'chapter-tasks.show',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings-request/:trainingId/task-results/:taskResultId/correction',
      name: 'superadmin-task-results.correction',
      component: () => import('@/views/apps/sekolah/training-request/classroom-task/task-correction/Essay.vue'),
      meta: {
        role: ['superadmin', 'admin', 'staff','staffsekolah'],
        contentClass: 'superadmin-trainings.index',
        navActiveLink: 'superadmin-trainings.index',
        pageTitle: 'Koreksi Tugas',
        back: 'trainings.show',
        tab: '2',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'trainings.index',
          },
          {
            text: 'Tugas',
            to: 'trainings.show',
          },
          {
            text: 'Koreksi Tugas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings-request/:trainingId/chapters/:chapterId/tasks/:taskId/essay-questions',
      name: 'superadmin-task-essay-questions.show',
      component: () => import('@/views/apps/sekolah/training-request/classroom-task/task-question/Essay.vue'),
      meta: {
        role: ['superadmin', 'admin', 'staff','staffsekolah'],
        contentClass: 'superadmin-trainings.index',
        navActiveLink: 'superadmin-trainings.index',
        pageTitle: 'Tugas',
        back: 'chapter-tasks.show',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'trainings.index',
          },
          {
            text: 'Detail Pelatihan',
            to: 'trainings.show',
          },
          {
            text: 'Tugas',
            to: 'chapter-tasks.show',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/staffsekolah/trainings-request/:trainingId/chapters/:chapterId/tasks/create',
      name: 'superadmin-chapter-tasks.create',
      component: () => import('@/views/apps/sekolah/training-request/classroom-task/classroom-task-add/ClassroomTaskAdd.vue'),
      meta: {
        role: ['superadmin', 'admin', 'staff','staffsekolah'],
        contentClass: 'superadmin-trainings.index',
        navActiveLink: 'superadmin-trainings.index',
        pageTitle: 'Tambah Tugas',
        back: 'trainings.show',
        breadcrumb: [
          {
            text: 'Pelatihan',
            to: 'trainings.index',
          },
          {
            text: 'Detail Pelatihan',
            to: 'trainings.show',
          },
          {
            text: 'Tambah Tugas',
            active: true,
          },
        ],
      },
    }
    
  ]
  