export default [
  // Teacher
  // Dashboard
  {
    path: '/trainer/certificates',
    name: 'trainer-certificates.index',
    component: () => import('@/views/apps/trainer/certificate/Index.vue'),
    meta: {
      role: ['trainer'],
      contentClass: 'trainer-certificates.index',
      pageTitle: 'Sertifikat',
      breadcrumb: [
        {
          text: 'Sertifikat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainer/certificates/:certificateId',
    name: 'trainer-certificates.show',
    component: () => import('@/views/apps/trainer/certificate/Show.vue'),
    meta: {
      role: ['trainer'],
      contentClass: 'trainer-certificates.index',
      navActiveLink: 'trainer-certificates.index',
      pageTitle: 'Detail Sertifikat',
      back: 'certificates.index',
      breadcrumb: [
        {
          text: 'Sertifikat',
          to: 'certificates.index',
        },
        {
          text: 'Detail Sertifikat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainer/dashboard',
    name: 'trainer-dashboard',
    component: () => import('@/views/apps/trainer/dashboard/Dashboard.vue'),
    meta: {
      role: ['trainer'],
      navActiveLink: 'trainer-dashboard',
    },
  },
  {
    path: '/trainer/trainings',
    name: 'trainer-trainings.index',
    component: () => import('@/views/apps/trainer/training/Index.vue'),
    meta: {
      role: ['trainer'],
      contentClass: 'trainer-trainings.index',
      navActiveLink: 'trainer-trainings.index',
      pageTitle: 'Pelatihan',
      breadcrumb: [
        {
          text: 'Pelatihan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainer/trainings/:trainingId',
    name: 'trainer-trainings.show',
    component: () => import('@/views/apps/trainer/training/classroom-detail/ClassroomDetail.vue'),
    meta: {
      role: ['superadmin', 'admin', 'trainer'],
      contentClass: 'trainer-trainings.index',
      navActiveLink: 'trainer-trainings.index',
      pageTitle: 'Detail Pelatihan',
      back: 'trainings.index',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          active: true,
        },
      ],
    },
  },
  // chapters
  {
    path: '/trainer/trainings/:trainingId/chapters/:chapterId',
    name: 'trainer-chapters.show',
    component: () => import('@/views/apps/trainer/training/classroom-material/ClassroomMaterial.vue'),
    meta: {
      role: ['superadmin', 'admin', 'trainer'],
      contentClass: 'trainer-trainings.index',
      navActiveLink: 'trainer-trainings.index',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainer/trainings/:trainingId/chapters/create',
    name: 'trainer-chapters.create',
    component: () => import('@/views/apps/trainer/training/classroom-material/classroom-material-add/ClassroomMaterialAdd.vue'),
    meta: {
      role: ['superadmin', 'admin', 'trainer'],
      contentClass: 'trainer-trainings.index',
      navActiveLink: 'trainer-trainings.index',
      pageTitle: 'Kelas',
      back: 'classroom-detail-material',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          to: 'classroom-detail-material',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },

  // Classroom Task
  {
    path: '/trainer/trainings/:trainingId/chapters/:chapterId/tasks',
    name: 'trainer-chapter-tasks.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/DetailTask.vue'),
    meta: {
      role: ['superadmin', 'admin', 'trainer'],
      contentClass: 'trainer-trainings.index',
      navActiveLink: 'trainer-trainings.index',
      pageTitle: 'Tugas',
      back: 'trainings.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainer/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/multiplechoice-questions',
    name: 'trainer-task-multiplechoice-questions.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/task-question/MultipleChoice.vue'),
    meta: {
      role: ['superadmin', 'admin', 'trainer'],
      contentClass: 'trainer-trainings.index',
      navActiveLink: 'trainer-trainings.index',
      pageTitle: 'Tugas',
      back: 'chapter-tasks.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          to: 'chapter-tasks.show',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainer/trainings/:trainingId/task-results/:taskResultId/correction',
    name: 'trainer-task-results.correction',
    component: () => import('@/views/apps/trainer/training/classroom-task/task-correction/Essay.vue'),
    meta: {
      role: ['superadmin', 'admin', 'trainer'],
      contentClass: 'trainer-trainings.index',
      navActiveLink: 'trainer-trainings.index',
      pageTitle: 'Koreksi Tugas',
      back: 'trainings.show',
      tab: 'grade',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Tugas',
          to: 'trainings.show',
        },
        {
          text: 'Koreksi Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainer/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/essay-questions',
    name: 'trainer-task-essay-questions.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/task-question/Essay.vue'),
    meta: {
      role: ['superadmin', 'admin', 'trainer'],
      contentClass: 'trainer-trainings.index',
      navActiveLink: 'trainer-trainings.index',
      pageTitle: 'Tugas',
      back: 'chapter-tasks.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          to: 'chapter-tasks.show',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trainer/trainings/:trainingId/chapters/:chapterId/tasks/create',
    name: 'trainer-chapter-tasks.create',
    component: () => import('@/views/apps/trainer/training/classroom-task/classroom-task-add/ClassroomTaskAdd.vue'),
    meta: {
      role: ['superadmin', 'admin', 'trainer'],
      contentClass: 'trainer-trainings.index',
      navActiveLink: 'trainer-trainings.index',
      pageTitle: 'Tambah Tugas',
      back: 'trainings.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tambah Tugas',
          active: true,
        },
      ],
    },
  },
]
