export default [
  // School Admin
  // Dashboard
  {
    path: '/tu/dashboard',
    name: 'tu-dashboard',
    component: () => import('@/views/apps/staff/dashboard/Dashboard.vue'),
    meta: {
      role: ['superadmin','tu'],
      navActiveLink: 'tu-dashboard',
    },
  },

  // LOA
  {
    path: '/tu/letter-of-assignments',
    name: 'tu-letter-of-assignments.index',
    component: () => import('@/views/apps/superadmin/letter-of-assignment/Index.vue'),
    meta: {
      role: ['superadmin', 'tu'],
      contentClass: 'tu.letter-of-assignments.index',
      navActiveLink: 'tu.letter-of-assignments.index',
    },
  },
  {
    path: '/tu/letter-of-assignments/create',
    name: 'tu-letter-of-assignments.create',
    component: () => import('@/views/apps/superadmin/letter-of-assignment/Create.vue'),
    meta: {
      role: ['superadmin', 'tu'],
      contentClass: 'tu.letter-of-assignments.index',
      navActiveLink: 'tu.letter-of-assignments.index',
    },
  },
  {
    path: '/tu/letter-of-assignments/:id/show',
    name: 'tu-letter-of-assignments.show',
    component: () => import('@/views/apps/superadmin/letter-of-assignment/Show.vue'),
    meta: {
      role: ['superadmin', 'tu'],
      contentClass: 'tu.letter-of-assignments.index',
      navActiveLink: 'tu.letter-of-assignments.index',
    },
  },
  {
    path: '/tu/letter-of-assignments/:id/edit',
    name: 'tu-letter-of-assignments.edit',
    component: () => import('@/views/apps/superadmin/letter-of-assignment/Edit.vue'),
    meta: {
      role: ['superadmin', 'tu'],
      contentClass: 'tu.letter-of-assignments.index',
      navActiveLink: 'tu.letter-of-assignments.index',
    },
  },
  {
    path: '/tu/trainings',
    name: 'tu-trainings.index',
    component: () => import('@/views/apps/trainer/training/Index.vue'),
    meta: {
      role: ['superadmin','tu'],
      contentClass: 'tu-trainings.index',
      navActiveLink: 'tu-trainings.index',
      pageTitle: 'Pelatihan',
      breadcrumb: [
        {
          text: 'Pelatihan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tu/trainings/create',
    name: 'tu-trainings.create',
    component: () => import('@/views/apps/trainer/training/Create.vue'),
    meta: {
      role: ['superadmin','tu'],
      contentClass: 'tu-trainings.index',
      navActiveLink: 'tu-trainings.index',
      pageTitle: 'Tambah Pelatihan',
      breadcrumb: [
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tu/trainings/:trainingId',
    name: 'tu-trainings.show',
    component: () => import('@/views/apps/trainer/training/classroom-detail/ClassroomDetail.vue'),
    meta: {
      role: ['superadmin', 'admin', 'tu'],
      contentClass: 'tu-trainings.index',
      navActiveLink: 'tu-trainings.index',
      pageTitle: 'Detail Pelatihan',
      back: 'trainings.index',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          active: true,
        },
      ],
    },
  },
  // chapters
  {
    path: '/tu/trainings/:trainingId/chapters/:chapterId',
    name: 'tu-chapters.show',
    component: () => import('@/views/apps/trainer/training/classroom-material/ClassroomMaterial.vue'),
    meta: {
      role: ['superadmin', 'admin', 'tu'],
      contentClass: 'tu-trainings.index',
      navActiveLink: 'tu-trainings.index',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tu/trainings/:trainingId/chapters/create',
    name: 'tu-chapters.create',
    component: () => import('@/views/apps/trainer/training/classroom-material/classroom-material-add/ClassroomMaterialAdd.vue'),
    meta: {
      role: ['superadmin', 'admin', 'tu'],
      contentClass: 'tu-trainings.index',
      navActiveLink: 'tu-trainings.index',
      pageTitle: 'Kelas',
      back: 'classroom-detail-material',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          to: 'classroom-detail-material',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },

  // Classroom Task
  {
    path: '/tu/trainings/:trainingId/chapters/:chapterId/tasks',
    name: 'tu-chapter-tasks.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/DetailTask.vue'),
    meta: {
      role: ['superadmin', 'admin', 'tu'],
      contentClass: 'tu-trainings.index',
      navActiveLink: 'tu-trainings.index',
      pageTitle: 'Tugas',
      back: 'trainings.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tu/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/multiplechoice-questions',
    name: 'tu-task-multiplechoice-questions.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/task-question/MultipleChoice.vue'),
    meta: {
      role: ['superadmin', 'admin', 'tu'],
      contentClass: 'tu-trainings.index',
      navActiveLink: 'tu-trainings.index',
      pageTitle: 'Tugas',
      back: 'chapter-tasks.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          to: 'chapter-tasks.show',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tu/trainings/:trainingId/task-results/:taskResultId/correction',
    name: 'tu-task-results.correction',
    component: () => import('@/views/apps/trainer/training/classroom-task/task-correction/Essay.vue'),
    meta: {
      role: ['superadmin', 'admin', 'tu'],
      contentClass: 'tu-trainings.index',
      navActiveLink: 'tu-trainings.index',
      pageTitle: 'Koreksi Tugas',
      back: 'trainings.show',
      tab: '2',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Tugas',
          to: 'trainings.show',
        },
        {
          text: 'Koreksi Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tu/trainings/:trainingId/chapters/:chapterId/tasks/:taskId/essay-questions',
    name: 'tu-task-essay-questions.show',
    component: () => import('@/views/apps/trainer/training/classroom-task/task-question/Essay.vue'),
    meta: {
      role: ['superadmin', 'admin', 'tu'],
      contentClass: 'tu-trainings.index',
      navActiveLink: 'tu-trainings.index',
      pageTitle: 'Tugas',
      back: 'chapter-tasks.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tugas',
          to: 'chapter-tasks.show',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tu/trainings/:trainingId/chapters/:chapterId/tasks/create',
    name: 'tu-chapter-tasks.create',
    component: () => import('@/views/apps/trainer/training/classroom-task/classroom-task-add/ClassroomTaskAdd.vue'),
    meta: {
      role: ['superadmin', 'admin', 'tu'],
      contentClass: 'tu-trainings.index',
      navActiveLink: 'tu-trainings.index',
      pageTitle: 'Tambah Tugas',
      back: 'trainings.show',
      breadcrumb: [
        {
          text: 'Pelatihan',
          to: 'trainings.index',
        },
        {
          text: 'Detail Pelatihan',
          to: 'trainings.show',
        },
        {
          text: 'Tambah Tugas',
          active: true,
        },
      ],
    },
  },


  {
    path: '/tu/training-requests',
    name: 'tu-training-requests.index',
    component: () => import('@/views/apps/staff/training-request/Index.vue'),
    meta: {
      role: ['superadmin','tu'],
      contentClass: 'tu-training-requests.index',
      pageTitle: 'Request Pelatihan',
      breadcrumb: [
        {
          text: 'Request Pelatihan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tu/training-requests/:trainingRequestId',
    name: 'tu-training-requests.show',
    component: () => import('@/views/apps/staff/training-request/Show.vue'),
    meta: {
      role: ['superadmin','tu'],
      contentClass: 'tu-training-requests.index',
      navActiveLink: 'tu-training-requests.index',
      pageTitle: 'Detail Request Pelatihan',
      back: 'training-requests.index',
      breadcrumb: [
        {
          text: 'Request Pelatihan',
          to: 'training-requests.index',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tu/training-requests/:trainingRequestId/edit',
    name: 'tu-training-requests.edit',
    component: () => import('@/views/apps/staff/training-request/Edit.vue'),
    meta: {
      role: ['superadmin','tu'],
      contentClass: 'tu-training-requests.index',
      navActiveLink: 'tu-training-requests.index',
      pageTitle: 'Edit Request Pelatihan',
      back: 'training-requests.index',
      breadcrumb: [
        {
          text: 'Request Pelatihan',
          to: 'training-requests.index',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
