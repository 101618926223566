export default [
  // Dashboard
  {
    path: '/reflection-dashboard',
    name: 'reflection-dashboard',
    component: () => import('@/views/apps/reflection/Dashboard.vue'),
    meta: {
      role: ['superadmin', 'ketenagaan'],
      navActiveLink: 'reflection-dashboard'
    },
  },
  {
    path: '/reflection-users',
    name: 'reflection-users.index',
    component: () => import('@/views/apps/reflection/Users.vue'),
    meta: {
      role: ['superadmin', 'ketenagaan'],
      navActiveLink: 'reflection-users.index'
    },
  },
  {
    path: '/reflection-questions',
    name: 'reflection-questions.index',
    component: () => import('@/views/apps/reflection/Questions.vue'),
    meta: {
      role: ['superadmin', 'ketenagaan'],
      navActiveLink: 'reflection-questions.index'
    },
  },
  {
    path: '/reflection-results',
    name: 'reflection-results.index',
    component: () => import('@/views/apps/reflection/Results.vue'),
    meta: {
      role: ['superadmin', 'ketenagaan'],
      navActiveLink: 'reflection-results.index'
    },
  },
  {
    path: '/reflection-trainings',
    name: 'reflection-trainings.index',
    component: () => import('@/views/apps/reflection/Trainings.vue'),
    meta: {
      role: ['superadmin', 'ketenagaan'],
      navActiveLink: 'reflection-trainings.index'
    },
  },
];
