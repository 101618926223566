export default [
  {
    path: '/ketenagaan/dashboard',
    name: 'ketenagaan-dashboard',
    component: () => import('@/views/apps/ketenagaan/dashboard/Dashboard.vue'),
    meta: {
      role: ['ketenagaan'],
      contentClass: 'ketenagaan-dashboard',
      navActiveLink: 'ketenagaan-dashboard',
    },
  },
]