export default [
  // Dashboard
  {
    path: "/bpsdm/dashboard",
    name: "bpsdm-dashboard",
    component: () => import("@/views/apps/bpsdm/dashboard/Dashboard.vue"),
    meta: {
      role: ["bpsdm"],
      contentClass: "bpsdm-dashboard",
      navActiveLink: "bpsdm-dashboard",
    },
  },
  {
    path: "/peserta/trainingsreq",
    name: "training-requests-peserta.index",
    component: () => import("@/views/apps/bpsdm/training-request/Index.vue"),
    meta: {
      role: ["superadmin", "admin", "tu", "staff", "staffsekolah", "bpsdm", "kadinas"],
      contentClass: "training-requests-peserta.index",
      navActiveLink: "training-requests-peserta.index",
      pageTitle: "Pelatihan",
      breadcrumb: [
        {
          text: "Pelatihan",
          active: true,
        },
      ],
    },
  },
  {
    path: "/bpsdm/trainings-request/:trainingId",
    name: "training-requests-peserta.show",
    component: () =>
      import(
        "@/views/apps/bpsdm/training-request/classroom-detail/ClassroomDetail.vue"
      ),
    meta: {
      role: ["superadmin", "admin", "staff", "staffsekolah", "bpsdm", "kadinas"],
      contentClass: "training-requests-peserta.index",
      navActiveLink: "training-requests-peserta.index",
      pageTitle: "Detail Pelatihan",
      back: "training-requests-peserta.index",
      breadcrumb: [
        {
          text: "Pelatihan",
          to: "training-requests-peserta.index",
        },
        {
          text: "Detail Pelatihan",
          active: true,
        },
      ],
    },
  },

  {
    path: "/bpsdm/trainings-request-bpsdm/:trainingId",
    name: "training-requests-pelatihan.show",
    component: () =>
      import(
        "@/views/apps/bpsdm/training-requests/classroom-detail/ClassroomDetail.vue"
      ),
    meta: {
      role: [
        "superadmin",
        "admin",
        "staff",
        "staffsekolah",
        "bpsdm",
        "kadinas",
      ],
      contentClass: "training-requests-pelatihan.index",
      navActiveLink: "training-requests-pelatihan.index",
      pageTitle: "Detail Pelatihan",
      back: "training-requests-pelatihan.index",
      breadcrumb: [
        {
          text: "Pelatihan",
          to: "training-requests-pelatihan.index",
        },
        {
          text: "Detail Pelatihan",
          active: true,
        },
      ],
    },
  },

  {
    path: "/kadinas/trainings-request-kadinas/:trainingId",
    name: "training-requests-pelatihan.show",
    component: () =>
      import(
        "@/views/apps/kadinas/training-requests/classroom-detail/ClassroomDetail.vue"
      ),
    meta: {
      role: [
        "superadmin",
        "admin",
        "staff",
        "staffsekolah",
        "bpsdm",
        "kadinas",
      ],
      contentClass: "training-requests-pelatihan.index",
      navActiveLink: "training-requests-pelatihan.index",
      pageTitle: "Detail Pelatihan",
      back: "training-requests-pelatihan.index",
      breadcrumb: [
        {
          text: "Pelatihan",
          to: "training-requests-pelatihan.index",
        },
        {
          text: "Detail Pelatihan",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pelatihan/trainingsreq",
    name: "training-requests-pelatihan.index",
    component: () => import("@/views/apps/bpsdm/training-requests/Index.vue"),
    meta: {
      role: ["superadmin", "admin", "tu", "staff", "staffsekolah", "bpsdm", "kadinas"],
      contentClass: "training-requests-pelatihan.index",
      navActiveLink: "training-requests-pelatihan.index",
      pageTitle: "Pelatihan",
      breadcrumb: [
        {
          text: "Pelatihan",
          active: true,
        },
      ],
    },
  },

  {
    path: "/bpsdm/trainings-request-pelatihan/:trainingId/chapters/:chapterId",
    name: "training-requests-pelatihan-chapters.show",
    component: () =>
      import(
        "@/views/apps/bpsdm/training-requests/classroom-material/ClassroomMaterial.vue"
      ),
    meta: {
      role: ["superadmin", "admin", "staff", "staffsekolah", "bpsdm", "kadinas"],
      contentClass: "training-requests-pelatihan.index",
      navActiveLink: "training-requests-pelatihan.index",
      pageTitle: "Kelas",
      back: "classroom-detail",
      breadcrumb: [
        {
          text: "Kelas",
          to: "classroom",
        },
        {
          text: "Detail",
          to: "classroom-detail",
        },
        {
          text: "Materi",
          active: true,
        },
      ],
    },
  },
];
